import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Divider, Button } from "@mui/material";

function MultiFactorAuth ({ name, Icon, hasEnrolled }) {
  return (
    <>
      <div className="w-100 p-2">
        <Container>
          <Row className="py-1">
            <Col xs={12} sm={8} className="d-flex pl-6 align-items-center">
              <div className="list-item-icon">
                <Image src={Icon} />
              </div>
              <div className="list-item-middle">
                <h5 className="list-item-title">{name}</h5>
                <p className="list-item-isenrolled">{hasEnrolled}</p>
              </div>
            </Col>

            <Col xs={12} sm={4} className="d-flex list-item-button align-items-center justify-content-sm-end">
              {hasEnrolled === "Enrolled using device biometrics" ? (
                <Button
                  size="small"
                  color="error"
                  className="border border-gray-900 w-75"
                >
                  Remove
                </Button>
              ) : (
                <Button
                  variant="dark"
                  size="small"
                  className="border border-gray-900 w-75"
                >
                  {hasEnrolled === "Enrolled" ? "Regenerate" : "+ Add"}
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Divider style={{ width: '100%' }} />
    </>
  );
}

export default MultiFactorAuth;
