/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions*/
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import "../styles/pages/profile.scss";
import { Divider } from "@mui/material";
import MultiFactorAuth from "../components/MultiFactorAuth/Index";
import {
  actionTypes,
} from "../store/Dialog/type";

const Profile = () => {
  const dispatch = useDispatch();

  const user = useSelector(
    (state) => state?.app?.authUser
  );

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const loading = useSelector(
    (state) => state?.app?.loading
  );

  const data = [
    {
      name: "SMS",
      hasEnrolled: "Not Enrolled",
      iconUrl: "/assets/images/icon_phone_01.png",
    },
    {
      name: "Google authenticator or similar",
      hasEnrolled: "Not Enrolled",
      iconUrl: "/assets/images/icon_phone_02.png",
    },
    {
      name: "SMS (Secondary)",
      hasEnrolled: "Not Enrolled",
      iconUrl: "/assets/images/icon_phone_01.png",
    },
    {
      name: "Recovery code",
      hasEnrolled: "Enrolled",
      iconUrl: "/assets/images/icon_phone_04.png",
    },
    {
      name: "My PC Name",
      hasEnrolled: "Enrolled using device biometrics",
      iconUrl: "/assets/images/icon_phone_05.png",
    },
  ];

  const updateEmail = () => {
    dispatch({ type: actionTypes.SHOW_DIALOG, value: { show: true, type: 'dialog_update_email' } })
  }

  const updatePhonenumber = () => {
    dispatch({ type: actionTypes.SHOW_DIALOG, value: { show: true, type: 'dialog_update_phonenumber' } })
  }
  return user ? (
    <Layout>
      <div style={{ minHeight: "calc(100vh - 400px)" }}>
        <Row className="justify-content-center mx-auto mw-1440 profile">
          {loading ? <Col md={12}><Loader /></Col> : <Col md={12}>
            <div className="banner rich-text">
              <h2>Profile &amp; Settings</h2>

              <p>
                <b>Name</b>
                <br />
                {user.name} 
              </p>

              <p>
                <b>Email address</b>
                <br />
                {user.email} 
              </p>

              <p>
                <b>Phone number</b>
                <br />
                {parentAccounts?.[0]?.phoneNo || ''} {parentAccounts?.[0]?.extension || ''} 
              </p>

              {/* <div className="multi-factor-conatainer">
                <h6 className="text-black">Multi-Factor Authentication</h6>
                <Divider style={{ width: '100%' }} />
                {data.map((item) => (
                  <MultiFactorAuth
                    name={item.name}
                    Icon={item.iconUrl}
                    hasEnrolled={item.hasEnrolled}
                  />
                ))}
              </div> */}
            </div>
          </Col>}
        </Row>
      </div>
    </Layout>
  ) : (
    <Loader />
  );
};

export default Profile;
